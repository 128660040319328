import cs from "classnames";

import styles from "./index.module.less";

type PCLandingPageOneWrapperProps = {
  className?: string;
  children?: React.ReactNode;
};

const PCLandingPageOneWrapper: React.FC<PCLandingPageOneWrapperProps> = ({
  children,
  className,
}) => {
  return (
    <section id="firstpage" className={cs(styles["first-page-container"], className)}>
      {children}
    </section>
  );
};

export default PCLandingPageOneWrapper;
