import { LANDING_ROUTE_KEY, QUERY_KEYS } from "@/enums";
import useRegister from "@/hooks/register/useRegister";
import useExposureTrack from "@/hooks/useExposureTrack";
import { useProfileContext } from "@/providers/ProfileProvider";
import BasicButton from "@/ui/BasicButton";
import JobrightLogo from "@/ui/JobrightLogo";
import PCSignInModal from "@/ui/PCSignInModal";
import MobileIcon from "@assets/newimages/nav/mobile_nav.svg";
import { useScroll } from "ahooks";
import { Button, ConfigProvider, Dropdown, Flex, MenuProps, Typography } from "antd";
import classNames from "classnames";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";

import { isEmpty } from "@/utils";
import { trackEvent } from "@/utils/trace";

import { LANDING_MENU_ITEM_ENUM, PROFILE_STATE_LINK_ENUM } from "@/constants";
import {
  ABOUT_PATHNAME,
  BLOG_PATHNAME,
  ENTERPRISE_INVITE_PATHNAME,
  LANDING_PATHNAME,
  MOBILE_APP,
  RESUME_LANDING_PATHNAME,
} from "@/constants/pathname";

import EnterpriseInvitePopup from "../EnterpriseInvitePopup";
import FlexRow from "../FlexRow";
import styles from "./index.module.less";

export const HEADER_HEIGHT = 64;

const FEATURE_ITEMS: MenuProps["items"] = [
  LANDING_ROUTE_KEY.JOB_MATCHING,
  LANDING_ROUTE_KEY.INSIDER_CONNECTIONS,
  LANDING_ROUTE_KEY.AI_COPILOT_ORION,
  LANDING_ROUTE_KEY.H1B_JOBS,
].map(key => ({
  key,
  label: (
    <Link target="_blank" rel="noopener noreferrer" href={LANDING_MENU_ITEM_ENUM[key]?.route}>
      {LANDING_MENU_ITEM_ENUM[key]?.text}
    </Link>
  ),
}));

const TOOLS_ITEMS: MenuProps["items"] = [
  LANDING_ROUTE_KEY.AI_JOB_ASSISTANT,
  LANDING_ROUTE_KEY.AI_COVER_LETTER_GENERATOR,
  LANDING_ROUTE_KEY.AI_RESUME_HELPER,
  LANDING_ROUTE_KEY.AI_JOB_TRACKER,
  LANDING_ROUTE_KEY.TOOLS_ASSEMBLE,
].map(key => ({
  key,
  label: (
    <Link target="_blank" rel="noopener noreferrer" href={LANDING_MENU_ITEM_ENUM[key]?.route}>
      {LANDING_MENU_ITEM_ENUM[key]?.text}
    </Link>
  ),
}));

type PCLandingHeaderProps = {
  className?: string;
  logoLink?: string;
  isEnterprise?: boolean;
};

const PCLandingHeader: React.FC<PCLandingHeaderProps> = ({ className, logoLink, isEnterprise }) => {
  const scroll = useScroll(typeof document !== "undefined" ? document : undefined);

  const [signInModalOpen, setSignInModalOpen] = useState(false);

  const { profile } = useProfileContext();

  const router = useRouter();

  const { register } = useRegister();

  useEffect(() => {
    if (router?.query?.login) {
      setSignInModalOpen(true);
    }
  }, [router?.query?.login]);

  const onShowSignModal = () => {
    setSignInModalOpen(true);
    trackEvent("signup_click");
  };

  const onCloseSignModal = () => {
    setSignInModalOpen(false);
  };

  useExposureTrack("landing_downloadapp_exposure", {
    utm_source: router?.query ? router?.query[QUERY_KEYS.UTM_SOURCE] : "",
    utm_campaign: router?.query ? router?.query[QUERY_KEYS.UTM_CAMPAIGN] : "",
  });

  const renderRightContent = () => {
    if (isEnterprise) {
      return (
        <EnterpriseInvitePopup
          buttonProps={{
            children: (
              <Flex gap={4} align="center">
                <Image src={"/newimages/public/chatpop.svg"} width={16} height={16} alt=">" />

                <Typography.Text>Contact US</Typography.Text>
              </Flex>
            ),
            className: styles["enterprise-invite-button"],
          }}
          buttonText="Contact US"
        />
      );
    }

    if (profile?.result?.logined) {
      return (
        <BasicButton type="primary" className={styles["continue-button"]}>
          <Link
            href={
              isEmpty(profile?.result?.currentStage)
                ? "/"
                : PROFILE_STATE_LINK_ENUM[profile?.result?.currentStage as number] || "/"
            }
          >
            <strong>Continue with Jobright</strong>
          </Link>
        </BasicButton>
      );
    }

    return (
      <>
        {/* <Button
          icon={<Image src={MobileIcon} width={16} height={16} alt="user" />}
          type="link"
          target="_blank"
          href={MOBILE_APP}
          onClick={() => {
            trackEvent("landing_downloadapp_click", {
              utm_source: router?.query ? router?.query[QUERY_KEYS.UTM_SOURCE] : "",
              utm_campaign: router?.query ? router?.query[QUERY_KEYS.UTM_CAMPAIGN] : "",
            });
          }}
          className={styles["download-button"]}
        >
          Download Our App
        </Button> */}
        <Typography.Text className={styles.text} onClick={onShowSignModal}>
          SIGN IN
        </Typography.Text>
        <Button
          type="primary"
          onClick={() => {
            register();
          }}
          className={styles["sign-up-button"]}
        >
          JOIN NOW
        </Button>

        {signInModalOpen && <PCSignInModal open={signInModalOpen} onCancel={onCloseSignModal} />}
      </>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            headerBg: "transparent",
            // colorBgHeader: "transparent",
          },
        },
        token: {
          colorPrimaryHover: "#00F0A0",
          colorBgBase: "transparent",
        },
      }}
    >
      <header
        className={classNames(
          styles["header-top"],
          {
            [styles["fixed-header"]]: scroll && scroll.top > HEADER_HEIGHT,
          },
          className
        )}
        style={{
          backgroundColor: `rgba(255, 255, 255, ${scroll?.top && scroll?.top / HEADER_HEIGHT})`,
        }}
      >
        <div
          className={styles["header-main"]}
          style={{
            height: HEADER_HEIGHT,
          }}
        >
          <Flex align="center" gap={80}>
            <Link href={{ pathname: logoLink || LANDING_PATHNAME, query: router.query }}>
              <div className={styles.logo}>
                <JobrightLogo theme="light" width={144} height={36} />
              </div>
            </Link>

            <Flex align="center" gap={32}>
              <Dropdown
                menu={{
                  items: FEATURE_ITEMS,
                }}
                trigger={["click"]}
                overlayClassName={styles["dropdown-overlay"]}
              >
                <Flex align="center" className={styles["navigator"]}>
                  Features
                  <Image src={"/newimages/public/drop_down.svg"} width={16} height={16} alt=">" />
                </Flex>
              </Dropdown>

              <Dropdown
                menu={{
                  items: TOOLS_ITEMS,
                }}
                trigger={["click"]}
                overlayClassName={styles["dropdown-overlay"]}
              >
                <FlexRow className={styles["navigator"]}>
                  Tools
                  <Image src={"/newimages/public/drop_down.svg"} width={16} height={16} alt=">" />
                </FlexRow>
              </Dropdown>
              <Link
                href={{ pathname: RESUME_LANDING_PATHNAME, query: router.query }}
                target="_blank"
                className={styles["navigator"]}
                onClick={() => {
                  trackEvent("landing_page_click_resume_ai");
                }}
              >
                Resume AI
              </Link>
              <Link
                href={{
                  pathname: ENTERPRISE_INVITE_PATHNAME,
                }}
                target="_blank"
                className={styles["navigator"]}
              >
                For Employer
              </Link>
              <Link
                href={{ pathname: ABOUT_PATHNAME, query: router.query }}
                target="_blank"
                className={styles["navigator"]}
                onClick={() => {
                  trackEvent("landing_page_click_about_us");
                }}
              >
                About Us
              </Link>
              <Link
                href={`${BLOG_PATHNAME}`}
                target="_blank"
                className={styles["navigator"]}
                onClick={() => {
                  trackEvent("landing_page_click_blog");
                }}
              >
                Blog
              </Link>
            </Flex>
          </Flex>
          <div className={styles.profile}>{renderRightContent()}</div>
        </div>
      </header>
    </ConfigProvider>
  );
};

export default PCLandingHeader;
