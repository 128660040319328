import { Collapse, CollapseProps, ConfigProvider } from "antd";
import classNames from "classnames";
import Image from "next/image";

import styles from "./index.module.less";

type CustomCollapseProps = CollapseProps & {};

const prefixCls = "custom-collapse-";

const SeoCustomCollapse: React.FC<CustomCollapseProps> = props => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Collapse: {
            contentBg: "#fff",
            headerBg: "#fff",
            contentPadding: "8px 0",
            headerPadding: "16px 0",
          },
        },

        token: {
          lineHeight: 24 / 14,
        },
      }}
    >
      <Collapse
        className={classNames(styles[prefixCls + "container"])}
        expandIconPosition="end"
        expandIcon={props => {
          return (
            <Image
              src={"/newimages/public/arr_down.svg"}
              width={16}
              height={16}
              alt="arr_down"
              style={{
                rotate: props.isActive ? "180deg" : "0deg",
              }}
            />
          );
        }}
        bordered={false}
        {...props}
      />
    </ConfigProvider>
  );
};

export default SeoCustomCollapse;
