import AutoSizeSquareImage from "@/ui/AutoSizeSquareImage";
import React from "react";

import styles from "./index.module.less";

type CompanyLogoProps = {
  logoSrc: string;
  width?: number;
  height?: number;
};

const CompanyLogo: React.FC<CompanyLogoProps> = props => {
  return (
    <div className={styles["company-logo"]}>
      <AutoSizeSquareImage
        className={styles["company-logo-img"]}
        src={props?.logoSrc}
        alt="company-logo"
        width={props.width || 72}
        height={props.height || 72}
        placeholder="blur"
        blurDataURL={"/newimages/public/img_none.svg"}
        errorSrc={"/newimages/public/img_none.svg"}
      />
      <div className={styles["company-logo-top-class"]}></div>
    </div>
  );
};

export default CompanyLogo;
