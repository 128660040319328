import { Typography } from "antd";
import React from "react";

export const TRY_JOBRIGHT_FOR_FREE_BUTTON_STYLE = {
  background: "#000",
  color: "#fff",
};

export const STEP_ITEM_GROUP = [
  "Upload your existing resume",
  "Get an instant analysis",
  "Get a brand new AI refined professional resume",
  "Download your resume!",
];

export const COLLAPSE_ITEM_GROUP = [
  {
    key: "1",
    label: <Typography.Title level={3}>Why should I use an AI Resume Editor?</Typography.Title>,
    children: (
      <>
        <p>Using an AI Resume Editor can help you in 3 major ways.</p>
        <br />
        <p>
          1. It significantly saves you time so you can send a larger volume of applications. Our
          users on average save 2 hrs of editing time per job application.
        </p>
        <br />

        <p>
          2. The AI Resume Editor provides smart recommendations for how to optimize your resume to
          be more competitive for respective jobs.
        </p>
        <br />

        <p>
          3. It can help you create a brand new, ATS friendly resume to increase your chances of
          grabbing the recruiter&apos;s attention. All this saves you precious time and eliminates
          unnecessary guesswork from the job search process.
        </p>
      </>
    ),
  },
  {
    key: "2",
    label: (
      <Typography.Title
        level={3}
      >{`Why is Jobright's AI Resume Editor the best?`}</Typography.Title>
    ),
    children: (
      <p>
        We value user experience and speed in bringing you the best AI Resume editor out there.
        While the others, including ChatGPT, are clunky and give generic, wordy responses, we
        provide a clean experience combined with the most advanced technology that is trained on
        over 10 million data parameters, and refined with the guidance of recruiting experts to
        bring you the most accurate editing experience possible that lead to resumes that are
        immediately highly usable.
      </p>
    ),
  },
  {
    key: "3",
    label: (
      <Typography.Title level={3}>
        I have a resume, just need some feedback. Is the AI Resume Editor relevant for me?
      </Typography.Title>
    ),
    children: (
      <p>
        Yes! Our AI Resume Editor helps you curate a new version of your resume to match different
        job descriptions. This means you will get specific personalized recommendations on your
        resume with each new application. Other features include a match score to the job, and an
        instant report card analysis to help around keywords and so much more!
      </p>
    ),
  },
];

export const OVERALL_BANNER_GROUP_CONFIG = [
  {
    title: "Build a new resume in Fast Mode",
    desc: "No matter where you are in your job search, get a polished resume in less than 3 minutes that is highly ATS compatible.",
    imageSrc: "/newimages/resume_landing/resume_2.png",
  },
  {
    title: "Guided AI Refinement for Every Job",
    desc: "Upload your resume and get an instant report card. Our advanced AI graph technology guides you on what should be added and eliminated for optimizing skills for your resume for every job description. Say goodbye to guesswork, forever!",
    imageSrc: "/newimages/resume_landing/resume_3.png",
    reverse: true,
  },
  {
    title: "Instant Job Matches Based on Your Resume",
    desc: "Once you have your refined resume, explore our job board and instantly get hundreds of job matches where you’ll be the top applicant! Create, edit, and apply - all from one place!",
    imageSrc: "/newimages/resume_landing/resume_4.png",
  },
];

export const MARQUEE_CARD_COUNT = [
  {
    value: "400,000+",
    desc: `Today's new jobs`,
  },
  {
    value: "8,000,000+",
    desc: "Total jobs",
  },
];

export const LANDING_CHAT_ITEMS = [
  {
    paragraph:
      "Job seekers have had to rely on tools that are decades old, jobright.ai is leveraging advanced cutting edge AI technology to offer personalized guidance throughout the entire job search process making it much easier and faster to land interviews. Finally there’s a smarter and efficient alternative! This is a game changer!",
    name: "Kenny Mendes",
    avatar: "/newimages/landing/km.jpeg",
    title: "HR leader, COO at Coda",
    width: "50%",
  },
  {
    paragraph:
      "In my interactions with hundreds of thousands of tech interviewees, I've met highly talented job seekers. They all express the pain of job hunting; many lack the core skills to land interviews fast. Jobright.ai is like an experienced AI career coach that streamlines the job search and resume editing process making career advancement in tech less confusing.",
    name: "Alex XU",
    avatar: "/newimages/landing/ax.jpeg",
    title: (
      <span>
        Author of System Design Interview <br /> - Amazon Bestseller
      </span>
    ),
    width: "60%",
  },
];

export const RESUME_LANDING_CHAT_ITEMS = [
  {
    paragraph:
      "omg i cannot believe i am getting a interview after using this.  the recommendations is well thought off. highly recommend everyone to use this website.",
    name: "Michelle V. ",
    avatar: "/newimages/resume_landing/mv.jpeg",
    title: "Sr. Digital Marketing Manager",
  },
  {
    paragraph:
      "The custom resume feature saved me so much time and effort.  It made sure my resume matched the job descriptions perfectly. I am gonna use it for every job I apply.",
    name: "Shiva V. ",
    avatar: "/newimages/resume_landing/sv.jpeg",
    title: "Instructional Designer",
  },
  {
    paragraph:
      "Really easy to use and understand. I loved how I could take direct action on suggestions and not just learn about them.",
    name: "Ken D. ",
    avatar: "/newimages/resume_landing/kd.jpeg",
    title: "Senior Recruiter",
  },
  {
    paragraph:
      "Imagine having an AI tool that generates a customized resume tailored to each job opportunity you’re eyeing. Jobright does just that!",
    name: "Subhasis D. ",
    avatar: "/newimages/resume_landing/sd.jpeg",
    title: "Senior Product Manager",
  },
];

export const HOME_LANDING_CHAT_ITEMS = [
  {
    paragraph:
      "I am able to find more relevant jobs faster, since using Jobright I have tripled my interview rate. I am truly impressed.",
    name: "Fred H.",
    avatar: "/newimages/landing/fh.jpeg",
    title: "Senior Software Engineer",
    width: "50%",
  },
  {
    paragraph:
      "Thanks to this platform I’ve landed a few interviews and accepted an offer within 1 week of interviewing!!",
    name: "Tracy C.",
    avatar: "/newimages/landing/tc.jpeg",
    title: "Sr. Digital Marketing Manager",
    width: "60%",
  },
  {
    paragraph:
      "You must check out Jobright. It has been saving me hours in my job search! I’m blown away at how easy it is to use!!",
    name: "Tyler S.",
    avatar: "/newimages/landing/ts.jpeg",
    title: "Instructional Designer",
    width: "46.67%",
  },
  {
    paragraph:
      "I’ve enjoyed seeing so many matching jobs. This has completely revamped my job search process. Excited to keep exploring!",
    name: "Chelsea L.",
    avatar: "/newimages/landing/cl.jpeg",
    title: "Senior Recruiter",
    width: "50%",
  },
  {
    paragraph:
      "It’s a 10/10!! Especially the resume editor which helps me very easily write the content to match the job description. The AI guidance and support has been game changing. Loving it so far!",
    name: "Brandi G.",
    avatar: "/newimages/landing/bg.jpeg",
    title: "Software Engineer",
    width: "46.67%",
  },
  {
    paragraph:
      "Not only does jobright show you the most relevant jobs it ALSO helps you network and get potential referrals! The matching system uses my experience, skills, and so much more to find the best fit. Definitely recommend!",
    name: "Gabriella B.",
    avatar: "/newimages/landing/gb.jpeg",
    title: "LinkedIn Strategist",
    width: "60%",
  },
];

export const APPLE_STORE = "https://apps.apple.com/us/app/jobright/id6738236788";
export const GOOGLE_STORE = "https://play.google.com/store/apps/details?id=ai.jobright.orion";
