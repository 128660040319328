import { Button, ButtonProps, ConfigProvider } from "antd";

const BasicButton: React.FC<ButtonProps> = ({ children, ...props }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#000000",
        },
      }}
    >
      <Button
        {...props}
        style={{
          transition: "none",
        }}
      >
        {children}
      </Button>
    </ConfigProvider>
  );
};

export default BasicButton;
