import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";

import { ONBOARDING_V3_SIGNUP_PATHNAME } from "@/constants/pathname";

const useRegister = () => {
  const router = useRouter();

  const register = useCallback(
    (query?: { [key: string]: string }) => {
      router.push({
        pathname: ONBOARDING_V3_SIGNUP_PATHNAME,
        query: { ...router?.query, ...query },
      });
    },
    [router]
  );

  const registerLink = useMemo(
    (query?: { [key: string]: string }) => {
      return {
        pathname: ONBOARDING_V3_SIGNUP_PATHNAME,
        query: { ...router?.query, ...query },
      };
    },
    [router]
  );

  return { register, registerLink };
};

export default useRegister;
